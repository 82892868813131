.side-bar {
  width: 280px;
  height: 100%;
  //border: 1px solid orangered;

  @media screen and (max-width: 970px) {
    display: none;
  }

  .show-hidden-pools {
    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        color: #e65634;
        font-size: 14px;
      }
    }

    .search {
      background: #11112280 0 0 no-repeat;
      width: 240px;
      height: 27px;
      padding: 3px 20px;
      border: 0;
      font-size: 14px;
      color: #ffffff;
    }
  }


  .collections,
  .token{
    text-align: left;
    color: #e65634;
    font-size: 14px;

    .selected-field{
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 280px;
      height: 33px;
      background: #000000 0 0 no-repeat;
      border-radius: 5px;
      color: #ffffff;
      border: 0;

      .token-select{
        width: 280px;
        height: 33px;
        background: #000000 0 0 no-repeat;
        border-radius: 5px;
        color: #ffffff;
        border: 0;

        .token__control{
          width: 280px;
          height: 33px;
          background: #000000 0 0 no-repeat;
          border-radius: 5px;
          color: #ffffff;
          border: 0;

          .token__indicator-separator{
            display: none;
          }

          .token__value-container{
            padding-left: 0;
            justify-content: start;
          }
        }

        .tokens-option{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
  
          img{
            width: 18px;
            margin-left: 8px;
          }
  
          span{
            font-size: 12px;
            color: #ffffff;
            transform: none!important;
            margin-left: 10px;
            font-family: 'Lexend-Medium', sans-serif;
          }


        }
      }

      .token__menu{
        background-color: #000000;
      }

      .token__option{
        justify-content: start;
        background-color: #000000;
        cursor: pointer;

        &:hover{
          background-color: #898585;
        }
      }

      span{
        font-size: 12px;
        // padding: 5px 0 0 0!important;

        transform: matrix(0, 1, -1, 0, 0, 0);
      }

      p{
        padding: 0 0 0 5px!important;
        font-size: 12px!important;
      }

      }
  }

  .collections {
    margin-top: 20px;
    p {
      text-align: left;
      color: #e65634;
      font-size: 14px;
      margin-bottom: 10px;
    }

    select {
      width: 280px;
      height: 33px;
      background: #000000 0 0 no-repeat;
      border-radius: 5px;
      color: #ffffff;
      border: 0;
      font-size: 12px;
    }
  }

  .schemas {
    margin-top: 20px;

    p {
      text-align: left;
      color: #e65634;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .schemas-list {
      width: 262px;
      height: 188px;
      background: #11112280 0 0;
      overflow: auto;
      padding: 10px 5px 5px 13px;

      p {
        color: #ffffff;
        font-size: 12px;
        text-align: left;
        cursor: pointer;

        &:hover {
          color: #d8d8d8;
        }
      }
    }
  }

  .tool-type {
    margin-top: 20px;

    p {
      text-align: left;
      color: #e65634;
      font-size: 14px;
      margin-bottom: 10px;
    }

    &-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 280px;
      height: 33px;
      background: #000000 0 0 no-repeat padding-box;
      border-radius: 5px;
      margin-bottom: 5px;

      p {
        color: #ffffff;
        font-size: 12px;
        padding: 10px;
        margin: 0;
      }

      input[type='checkbox'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        background: #2b282c;
        border: 1px solid #707070;
        cursor: pointer;
        margin-right: 10px;
      }
      input[type='checkbox']:checked::before {
        content: '\2713';
        margin: -2px 2px;
        font-size: 12px;
        color: #fff;
        //background-color: #2B282C;
        width: 13px;
        height: 13px;
        border-radius: 3px;
        display: block;
      }
    }
  }
}
