.pool-page-section {
  margin-top: 25px;

  h5 {
    font-size: 18px;
  }

  &_title {
    color: #ffffff;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .wax-title {
      display: flex;
      align-items: center;
      font-family: Lexend-ExtraLight, sans-serif;

      p {
        font-size: 40px;

        @media screen and (max-width: 559px) {
          font-size: 25px;
        }
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
    }

    .arrow {
      width: 24px;
      height: 24px;
      margin: 0 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .name-collection {
      display: flex;
      align-items: center;
      font-size: 40px;
      font-family: Lexend-ExtraLight, sans-serif;

      @media screen and (max-width: 559px) {
        font-size: 25px;
      }

      img {
        width: 50px;
        margin-right: 12px;
      }
    }
  }

  .assets-block-sell {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-bottom: 17px;

    @media screen and (max-width: 1090px) {
      flex-direction: column;
    }

    .assets_title {
      margin-bottom: 12px !important;
    }

    .assets {
      width: 521px;
      height: 360px;
      background: #111122;
      border-radius: 5px;
      margin-right: 10px;

      @media screen and (max-width: 1090px) {
        width: 754px;
        margin-right: 0;
        margin-bottom: 11px;
      }

      @media screen and (max-width: 787px) {
        width: 500px;
      }

      @media screen and (max-width: 520px) {
        width: 350px;
      }

      &_title {
        padding-top: 11px;
        margin-bottom: 24px;
        font-size: 18px;
      }

      .deposit-field {
        width: 440px;
        height: 52px;
        //background: #000000;
        margin: 0 auto;

        @media screen and (max-width: 1090px) {
          width: 672px;
        }

        @media screen and (max-width: 787px) {
          width: 450px;
        }

        @media screen and (max-width: 520px) {
          width: 280px;
        }

        .wrapper {
          height: 100%;
          padding: 10px 14px 10px 10px;
          display: flex;
          justify-content: space-between;

          p {
            text-align: left;
            margin-bottom: 11px;
          }

          .nfts {
            p:first-child {
              color: #d8d8d8;
              font-size: 12px;
            }
            p:last-child {
              color: #ffffff;
              font-size: 22px;
            }
          }

          .deposit-withdraw-btn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .deposit-btn,
            .withdraw-btn,
            .save-btn {
              color: #ffffff;
              font-size: 12px;
              width: 93px;
              height: 24px;
              background: #2b282c;
              border-radius: 15px;
              border: none;

              &:hover {
                background: rgba(43, 40, 44, 0.87);
              }
            }
          }

          button {
            display: block;
          }
        }
      }

      .nfts-field {
        width: 440px;
        height: 229px;
        background: #000000;
        margin: 23px auto 0 auto;
        box-sizing: border-box;

        @media screen and (max-width: 1090px) {
          width: 672px;
        }

        @media screen and (max-width: 787px) {
          width: 450px;
        }

        @media screen and (max-width: 520px) {
          width: 280px;
        }

        &.withdraw {
          border: 2px dashed #6f7f8f;
          background: rgba(0, 0, 0, 0.48);
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          height: 88%;
          padding: 10px;
          overflow: auto;
          justify-content: center;

          &_item {
            width: 120px;
            height: 160px;
            border-radius: 10px;
            background: #111122;
            margin-right: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;

            &.active {
              border: 1px solid #e65634;
            }

            &.withdraw {
              cursor: pointer;
            }

            &-image {
              width: 110px;
              height: 110px;
              border-radius: 10px;
              margin: 7px auto 5px auto;

              img {
                height: 100%;
              }
            }

            &-info {
              text-align: center;
              padding: 0 10px;
              p {
                font-size: 10px;
              }

              h6 {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .about {
      .pricing {
        width: 521px;
        height: 147px;
        background: #111122;
        border-radius: 5px;
        margin-bottom: 14px;
        position: relative;

        @media screen and (max-width: 1090px) {
          width: 754px;
        }

        @media screen and (max-width: 787px) {
          width: 500px;
          margin: 0 auto 14px auto;
        }

        @media screen and (max-width: 520px) {
          width: 350px;
        }

        .edit {
          position: absolute;
          top: 10px;
          right: 10px;

          button {
            color: #ffffff;
            font-size: 12px;
            width: 93px;
            height: 24px;
            background: #2b282c;
            border-radius: 15px;
            border: none;

            &:hover {
              background: rgba(43, 40, 44, 0.87);
            }
          }
        }

        &_title {
          padding-top: 11px;
          margin-bottom: 24px;
        }

        .info {
          display: flex;
          justify-content: center;
          align-items: center;

          .current-price {
            width: 218px;
            height: 76px;
            background: #000000;
            margin-right: 7px;
            text-align: left;
            justify-content: center;

            @media screen and (max-width: 1090px) {
              width: 334px;
            }

            .wrapper {
              padding: 10px 14px 10px 10px;

              .little-title {
                color: #999999;
                font-size: 12px;
                text-align: left;
                margin-bottom: 12px;
              }
              .floor_price {
                font-size: 18px;

                @media screen and (max-width: 520px) {
                  font-size: 15px;
                }
              }
              //p:last-child{
              //  font-size: 22px;
              //}
            }
          }

          .delta {
            width: 218px;
            height: 76px;
            background: #000000;
            margin-right: 7px;
            text-align: left;

            @media screen and (max-width: 1090px) {
              width: 334px;
            }

            .wrapper {
              padding: 10px 14px 10px 10px;

              .little-title {
                color: #999999;
                font-size: 12px;
                text-align: left;
                margin-bottom: 12px;
              }

              .delta-number {
                font-size: 18px;

                @media screen and (max-width: 520px) {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }

      .about-owner {
        width: calc(487px - 38px);
        height: 199px;
        background: #111122;
        border-radius: 5px;
        padding: 0 38px;

        @media screen and (max-width: 1090px) {
          width: calc(754px - 67px);
          padding: 0 19px 0 48px;
          height: 319px;
        }

        @media screen and (max-width: 787px) {
          width: 435px;
        }

        @media screen and (max-width: 520px) {
          width: 287px;
        }

        &_title {
          padding-top: 11px;
          margin-bottom: 24px;
        }

        &_info {
          text-align: left;
          p {
            font-family: Lexend-Medium, sans-serif;
            font-size: 12px;
            margin-bottom: 8px;

            @media screen and (max-width: 1090px) {
              font-size: 16px;
            }

            @media screen and (max-width: 520px) {
              font-size: 14px;
            }
          }

          &-address {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 188px;
            height: 33px;
            background: #2b282c;
            border-radius: 15px;
            margin-bottom: 22px;

            @media screen and (max-width: 1090px) {
              margin-bottom: 39px;
            }
            p {
              font-size: 14px;
              margin-bottom: 0;

              @media screen and (max-width: 1090px) {
                font-size: 16px;
              }
            }
          }
        }

        &_pool-info {
          text-align: left;
          font-family: Lexend-Medium, sans-serif;
          font-size: 12px;

          @media screen and (max-width: 1090px) {
            font-size: 18px;
          }

          @media screen and (max-width: 520px) {
            font-size: 14px;
          }

          .mini-wax-logo {
            width: 10px;
            height: 10px;
          }

          span {
            color: #e65634;
          }
        }
      }
    }
  }

  .diagram-block {
    width: 1064px;
    min-height: calc(487px - 20px);
    background: #111122;
    border-radius: 5px;
    margin: 0 auto 60px auto;
    padding-bottom: 20px;

    @media screen and (max-width: 1090px) {
      width: 754px;
    }

    @media screen and (max-width: 787px) {
      width: 500px;
    }

    @media screen and (max-width: 520px) {
      width: 350px;
    }

    &_title {
      padding-top: 22px;
      margin-bottom: 28px;
      color: #ffffff;
      font-size: 18px;
    }

    .amount-slider {
      width: 227px;
      height: 99px;
      background: #000000;
      margin: 28px auto 28px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h4 {
        color: #ffffff;
        font-size: 12px;
      }

      p {
        color: #ffffff;
        font-size: 12px;
        margin-top: 10px;

        @media screen and (max-width: 520px) {
          margin-top: 15px;
        }
      }
    }

    .diagram-block-content {
      max-width: 941px;
      margin: 0 auto;

      //canvas{
      //  display: block;
      //  box-sizing: border-box;
      //  height: 400px;
      //  width: 800px;
      //  margin: 0 auto;
      //}
    }
  }
}
