.MuiFormControlLabel-root {
  margin-bottom: 0px;
}

.css-j204z7-MuiFormControlLabel-root {
  margin: 0 !important;
}

.MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #ffffff;
  font-size: 18px;
  font-family: 'Lexend-Regular', sans-serif;
}

.MuiSwitch-thumb {
  background-color: #e65634 !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track,
.MuiSwitch-track {
  background-color: transparent !important;
  border: 1px solid orange;
}

.MuiButtonBase-root.MuiSwitch-switchBase .MuiSwitch-thumb {
  background-color: #8e8e8e !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background-color: #e65634 !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
  color: #e65634 !important;
}
