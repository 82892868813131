.navigation-buttons{
    display: flex;
    justify-content: space-between;

    button.disabled{
      border: 1px solid #5e5e5e;
      color: #5e5e5e;
      cursor: default;
    }

    button{
      border: 1px solid #E65634;
      background: transparent;
      color: #FFFFFF;
      padding: 8px 51px;
    }

    .finish-action-btns{
      display: flex;
      gap: 25px;
    }
  }
