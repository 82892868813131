.collections-page-title {
  h2 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 200;
    margin: 30px 0 11px;
    font-family: Lexend-ExtraLight, sans-serif;
  }
  p {
    color: #ffffff;
    margin-bottom: 3px;

    span {
      color: #e65634;
    }
  }
}

.collections-page-table {
  max-width: 892px;
  margin: 0 auto;
}
