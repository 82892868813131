.orange-button {
  margin-right: 15px;
  background-color: #e65634;
  border: 2px solid #ffa28d;
  width: 115px;
  height: 33px;
  background-repeat: no-repeat;
  font-size: 0.875rem;
  padding: 0 28px;
  text-align: center;
  color: #ffffff;
  font-weight: 500;

  &:hover {
    color: #000000;
    background-color: #ffffff;
  }
}
