.swap-page-offer {
  color: #ffffff;
  width: 1170px;
  margin: 0 auto 50px auto;

  @media screen and (max-width: 1015px) {
    width: 440px;
  }

  @media screen and (max-width: 540px) {
    width: 320px;
  }

  &_title {
    margin: 40px 0 16px 0;
    h2 {
      font-size: 40px;
      font-family: "Lexend-ExtraLight", sans-serif;
    }
  }

  &_subtitle {
    margin: 0 0 31px 0;
    color: #FF6C4A;
    p {
      font-size: 18px;
    }
  }

  .pool-infos{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 24px;

    .pool-block {
      width: 580px;
      height: 560px;
      background: #111122;
      border-radius: 5px;
      margin: 0 auto;
  
      @media screen and (max-width: 1015px) {
        width: 440px;
      }
  
      @media screen and (max-width: 540px) {
        width: 320px;
      }
  
      &_wrapper {
        text-align: left;
        padding: 27px 32px;
        
        .collections, .schemas{
          span{
            color: #D8D8D8;
            font-size: 14px;
          }
          p{
            margin-top: 16px;
            margin-bottom: 32px;
            color: #FFFFFF;
            font-size: 16px;
          }
        }

        .templates{
          span{
            color: #D8D8D8;
            font-size: 14px;
          }
          p{
            margin-top: 16px;
            margin-bottom: 32px;
            color: #FFFFFF;
            font-size: 16px;
          }

          &-list{
            margin-top: 16px;
            height: 280px;
            overflow: auto;
          }
          &-item{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-top: 5px;
            margin-bottom: 10px;

            &_amount{
              background: #000000;
              border-radius: 8px;
              padding: 4px 13px 11px 13px;
              text-align: center;
              span{
                font-size: 14px;
              }
              p{
                margin-top: 0;
                margin-bottom: 0;
                color: #FFFFFF;
                font-size: 16px;
              }
            }
            &_info{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              background: #000000;
              border-radius: 8px;
             
              p{
                padding: 15px 0 ;
                margin-top: 0;
                margin-bottom: 0;
                color: #FFFFFF;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  
    .nfts-block {
      width: 580px;
      text-align: left;
      width: 580px;
      height: 560px;
      background: #111122;

      &_wrapper{
        padding: 17px 32px;
      }

      &-filters{
        margin-bottom: 9px;
      }
  
  
      h4 {
        font-size: 20px;
      }
  
      &_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 20px 0;
        width: 99%;
        height: 400px;
        background-color: #000000;
        padding: 20px 10px;
        overflow: auto;
  
        @media screen and (max-width: 1015px) {
          margin: 15px auto 0 auto;
        }
  
        &_item {
          width: 120px;
          height: 160px;
          border-radius: 10px;
          background: #111122;
          margin-right: 5px;
          margin-bottom: 5px;
          cursor: pointer;
  
          @media screen and (max-width: 540px) {
            width: 130px;
          }
  
          &-image {
            display: flex;
            align-items: center;
            width: 110px;
            height: 110px;
            border-radius: 10px;
            margin: 7px auto 5px auto;
            text-align: center;
  
            img {
              width: 100%;
              height: 100%;
            }
          }
  
          &-info {
            text-align: center;
            p {
              font-size: 10px;
            }
  
            h6 {
              font-size: 12px;
            }
          }
  
          &:hover {
            transform: scale(1.02);
          }
  
          &.active {
            border: 1px solid #e65634;
          }
  
          img {
            //width: 100%;
            height: 100%;
          }
        }
      }
    }
    
    .amount-block{
      margin-top: 13px;
      width: 580px;
      height: 80px;
      background: #111122;
      border-radius: 8px;
      display: flex;
      align-items: center;


      p{
        text-align: left;
        color: #FFFFFF;
        font-size: 20px;
        margin-left: 38px;
      }
    }
  }

  .confirmation-block{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    &_receive{
      width: 100%;
      height: 296px;
      background: #111122;
      border-radius: 16px;

      .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 23px;
        height: 60px;
        font-size: 22px;
        border-bottom: 1px solid #000000;
        margin-bottom: 25px;
      }

      .asset-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        overflow-y: auto;
        height: 180px;

        .asset-item{
          width: 115px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
  
          &_img{
            width: 85px;
            // height: 120px;
            margin-bottom: 4px;
          }
  
          &_id{
            color: #D8D8D8;
            font-size: 12px;
          }
  
          &_name{
            color: #D8D8D8;
            font-size: 16px;
          }
        }
      }
     
    }

    &_send{
      width: 100%;
      height: 296px;
      background: #111122;
      border-radius: 16px;

      .title{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 23px;
        height: 60px;
        font-size: 22px;
        border-bottom: 1px solid #000000;
        margin-bottom: 25px;
      }

      .asset-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        overflow-y: auto;
        height: 180px;
        
        .asset-item{
          width: 115px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
  
          &_img{
            width: 85px;
            margin-bottom: 4px;
          }
  
          &_id{
            color: #D8D8D8;
            font-size: 12px;
          }
  
          &_name{
            color: #D8D8D8;
            font-size: 16px;
          }
        }
      }
    }
  }

  .cost{
    font-size: 22px;
    text-align: right;
    margin: 73px 0 24px 0;

    span{
      color: #FF6C4A;
    }
  }
 
}
