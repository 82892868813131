.collection {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/Rectangle 19.png');
    background-size: cover;
  }

  &-header {
    max-width: 100%;
    height: 234px;
    overflow: hidden;
    position: relative;

    &_img {
      width: 100%;
      height: 500px;
      margin-right: 37px;

      img {
        width: 100%;
      }
    }

    &_name {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 22px;

      p {
        color: #ffffff;
        font-size: 40px;
      }

      .logo {
        margin-right: 37px;
        width: 80px;
        height: 80px;

        img {
          width: 100%;
        }
      }
    }

    &_about {
      max-width: 718px;
      height: 42px;
      margin: 0 auto;

      &_wrapper {
        display: flex;
        justify-content: space-between;
        height: 100%;

        .floor {
          text-align: center;
          h6 {
            font-size: 12px;
            color: #e65634;
          }

          p {
            font-size: 16px;
            color: #ffffff;
          }
        }

        .best {
          text-align: center;
          h6 {
            font-size: 12px;
            color: #e65634;
          }

          p {
            font-size: 16px;
            color: #ffffff;
          }
        }

        .volume {
          text-align: center;
          h6 {
            font-size: 12px;
            color: #e65634;
          }

          p {
            font-size: 16px;
            color: #ffffff;
          }
        }

        .total {
          text-align: center;
          h6 {
            font-size: 12px;
            color: #e65634;
          }

          p {
            font-size: 16px;
            color: #ffffff;
          }
        }
      }
    }
  }

  &-content {
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-top: 20px;

      .active {
        border-bottom: 2px solid #ffffff;
      }

      p {
        font-size: 14px;
        color: #cbcbcb;
        cursor: pointer;
        padding-bottom: 7px;
      }
    }

    &_container {
      display: flex;
      justify-content: center;
      min-height: 510px;
    }

    &_list {
      width: 1170px;
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      @media screen and (max-width: 1500px) {
        width: 855px;
      }

      a {
        height: 272px;
        margin-bottom: 8px;
      }

      .loader-data{
        width: 100%;
      }
    }

    &_sidebar {
      width: 290px;
      margin-right: 32px;
      margin-top: 30px;

      .min-max-price {
        margin-bottom: 22px;
        p {
          text-align: left;
          font-size: 14px;
          color: #e65634;
        }

        .inputs {
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          .min,
          .max {
            width: 124px;
            height: 33px;
            background: #11112280;
            border: 0;
            padding: 0 10px 0 5px;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }

      .pool-type {
        margin-bottom: 30px;

        &_p {
          text-align: left;
          font-size: 14px;
          color: #e65634;
          margin-bottom: 10px;
        }

        &_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 33px;
          background: #000000;
          border-radius: 5px;
          color: #ffffff;
          margin-bottom: 4px;

          p {
            padding-left: 13px;
            font-size: 12px;
            font-family: Lexend-Regular, sans-serif;
          }

          input[type='checkbox'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            background: #2b282c;
            border: 1px solid #707070;
            cursor: pointer;
            margin-right: 10px;
          }
          input[type='checkbox']:checked::before {
            content: '\2713';
            margin: -2px 2px;
            font-size: 12px;
            color: #fff;
            //background-color: #2B282C;
            width: 13px;
            height: 13px;
            border-radius: 3px;
            display: block;
          }
        }
      }

      .bonding-curve {
        &_p {
          text-align: left;
          font-size: 14px;
          color: #e65634;
          margin-bottom: 10px;
        }

        &_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 33px;
          background: #000000;
          border-radius: 5px;
          color: #ffffff;
          margin-bottom: 4px;

          p {
            padding-left: 13px;
          }

          input[type='checkbox'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            background: #2b282c;
            border: 1px solid #707070;
            cursor: pointer;
            margin-right: 10px;
          }
          input[type='checkbox']:checked::before {
            content: '\2713';
            margin: -2px 2px;
            font-size: 12px;
            color: #fff;
            //background-color: #2B282C;
            width: 13px;
            height: 13px;
            border-radius: 3px;
            display: block;
          }
        }
      }
    }
  }
}
