.staking-section {
  max-width: 860px;
  margin: 0 auto;

  @media screen and (max-width: 950px) {
    margin: 0 30px;
  }

  &-title {
    margin-bottom: 30px;

    h2 {
      font-size: 40px;
      color: #ffffff;
      font-weight: 200;
      margin: 30px 0 11px;
      font-family: Lexend-ExtraLight, sans-serif;
    }
  }

  &-info {
    margin-bottom: 20px;

    p {
      color: #ffffff;
      text-align: left;

      @media screen and (max-width: 950px) {
        text-align: center;
      }

      span {
        color: #e65634;
      }
    }
  }

  &-tables {
    display: flex;
    gap: 24px;
    margin-bottom: 30px;

    @media screen and (max-width: 950px) {
      flex-direction: column;
      align-items: center;
    }

    .staking-section-table-1 {
      width: 436px;
      min-height: 290px;
      background: #111122 0 0 no-repeat padding-box;
      border-radius: 5px;
      padding: 36px 38px 35px 46px;

      @media screen and (max-width: 578px) {
        width: 230px;
      }

      .staking-section-table_row-1,
      .staking-section-table_row-2,
      .staking-section-table_row-3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;

        @media screen and (max-width: 578px) {
          flex-direction: column;
          gap: 15px;
        }

        span {
          font-size: 14px;
        }

        p {
          font-size: 16px;
        }
      }

      .staking-section-table_row-1,
      .staking-section-table_row-2 {
        border-bottom: 1px solid #000000;
        padding: 35px 0;
      }

      .staking-section-table_row-1 {
        justify-content: center;
        padding-top: 0;
      }

      .staking-section-table_row-2 {
        button:first-child {
          margin-bottom: 8px;
        }
        button:last-child {
          margin-top: 8px;
        }
      }

      .staking-section-table_row-3 {
        padding: 25px 0 0 0;
        margin-top: 8px;
      }
    }

    .staking-section-table-2 {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: #ffffff;
      text-align: left;

      @media screen and (max-width: 950px) {
        order: -1;
      }

      &_row-1,
      &_row-2 {
        width: 239px;
        height: 48px;
        background: #000000 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 28px 38px;
      }

      &_row-1 {
        overflow-y: auto;

        .reward-item {
          margin-bottom: 8px;
        }
      }
    }
  }
}
