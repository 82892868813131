.rodal-stake {
  .rodal-dialog {
    width: 536px !important;
    height: 220px !important;
    color: #ffffff;

    @media screen and (max-width: 615px) {
      width: 300px !important;
    }

    h3 {
      font-size: 16px;
      font-family: Lexend-Regular, sans-serif;
      margin-top: 30px;
      margin-bottom: 43px;
    }
    .rodal-stake-content {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &_field {
        display: flex;
        align-items: center;
        gap: 16px;

        input {
          background-color: #000000;
          border: none;
          color: #ffffff;
          width: 280px;
          height: 15px;
          padding: 10px;
        }
      }

      p {
        color: #d8d8d8;
        font-size: 14px;
        margin-bottom: 12px;

        span {
          color: #e65634;
          font-size: 14px;
        }
      }

      span {
        font-size: 12px;
        color: #8e8e8e;
        margin-top: 8px;
      }
    }

    &:after {
      display: none;
    }
  }
}
