.pool-page-section {
  margin-top: 25px;

  h5 {
    font-size: 18px;
  }

  &_title {
    color: #ffffff;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    .wax-title {
      display: flex;
      align-items: center;
      font-family: Lexend-ExtraLight, sans-serif;

      p {
        font-size: 40px;

        @media screen and (max-width: 559px) {
          font-size: 25px;
        }
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
    }

    .arrow {
      width: 24px;
      height: 24px;
      margin: 0 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .name-collection {
      display: flex;
      align-items: center;
      font-size: 40px;
      font-family: Lexend-ExtraLight, sans-serif;

      @media screen and (max-width: 559px) {
        font-size: 25px;
      }

      img {
        width: 50px;
        margin-right: 12px;
      }
    }
  }

  .assets-block-offer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #ffffff;
    margin-bottom: 17px;

    @media screen and (max-width: 1090px) {
      flex-direction: column;
    }

    .assets_title {
      margin-bottom: 12px !important;
    }

    .assets {
      width: 521px;
      height: 360px;
      background: #111122;
      border-radius: 5px;
      margin-right: 10px;

      @media screen and (max-width: 1090px) {
        width: 754px;
        margin-right: 0;
        margin-bottom: 11px;
      }

      @media screen and (max-width: 787px) {
        width: 500px;
      }

      @media screen and (max-width: 520px) {
        width: 350px;
      }

      &_title {
        padding-top: 11px;
        margin-bottom: 24px;
        font-size: 18px;
      }

      .deposit-field {
        width: 440px;
        height: 52px;
        //background: #000000;
        margin: 0 auto;

        @media screen and (max-width: 1090px) {
          width: 672px;
        }

        @media screen and (max-width: 787px) {
          width: 450px;
        }

        @media screen and (max-width: 520px) {
          width: 280px;
        }

        .wrapper {
          height: 100%;
          padding: 10px 14px 10px 10px;
          display: flex;
          justify-content: space-between;

          p {
            text-align: left;
            margin-bottom: 11px;
          }

          .nfts {
            p:first-child {
              color: #d8d8d8;
              font-size: 12px;
            }
            p:last-child {
              color: #ffffff;
              font-size: 22px;
            }
          }

          .deposit-withdraw-btn {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .deposit-btn,
            .withdraw-btn,
            .save-btn {
              color: #ffffff;
              font-size: 12px;
              width: 93px;
              height: 24px;
              background: #2b282c;
              border-radius: 15px;
              border: none;

              &:hover {
                background: rgba(43, 40, 44, 0.87);
              }
            }
          }

          button {
            display: block;
          }
        }
      }

      .nfts-field {
        width: 440px;
        height: 229px;
        background: #000000;
        margin: 23px auto 0 auto;
        box-sizing: border-box;

        @media screen and (max-width: 1090px) {
          width: 672px;
        }

        @media screen and (max-width: 787px) {
          width: 450px;
        }

        @media screen and (max-width: 520px) {
          width: 280px;
        }

        &.withdraw {
          border: 2px dashed #6f7f8f;
          background: rgba(0, 0, 0, 0.48);
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          height: 88%;
          padding: 10px;
          overflow: auto;
          justify-content: center;

          &_item {
            width: 120px;
            height: 160px;
            border-radius: 10px;
            background: #111122;
            margin-right: 5px;
            margin-bottom: 5px;
            box-sizing: border-box;

            &.active {
              border: 1px solid #e65634;
            }

            &.withdraw {
              cursor: pointer;
            }

            &-image {
              display: flex;
              align-items: center;
              width: 110px;
              height: 110px;
              border-radius: 10px;
              margin: 7px auto 5px auto;

              img {
                width: 100%;
                height: 100%;
              }
            }

            &-info {
              text-align: center;
              padding: 0 10px;
              p {
                font-size: 10px;
              }

              h6 {
                font-size: 12px;
              }
            }
          }
        }
      }

      .about-owner {
        width: calc(483px - 38px);
        height: 127px;
        background: #111122;
        border-radius: 5px;
        padding: 0 38px;
        margin-top: 15px;

        @media screen and (max-width: 1090px) {
          width: calc(754px - 67px);
          padding: 0 19px 0 48px;
          height: 319px;
        }

        @media screen and (max-width: 787px) {
          width: 435px;
        }

        @media screen and (max-width: 520px) {
          width: 287px;
        }

        &_title {
          padding-top: 11px;
          margin-bottom: 5px;
        }

        &_info {
          text-align: left;
          p {
            font-family: Lexend-Medium, sans-serif;
            font-size: 14px;
            margin-bottom: 8px;

            @media screen and (max-width: 1090px) {
              font-size: 16px;
            }

            @media screen and (max-width: 520px) {
              font-size: 14px;
            }
          }

          &-address {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 188px;
            height: 33px;
            background: #2b282c;
            border-radius: 15px;
            margin-bottom: 22px;

            @media screen and (max-width: 1090px) {
              margin-bottom: 39px;
            }
            p {
              font-size: 16px;
              margin-bottom: 0;

              @media screen and (max-width: 1090px) {
                font-size: 16px;
              }
            }
          }
        }

        &_pool-info {
          text-align: left;
          font-family: Lexend-Medium, sans-serif;
          font-size: 12px;

          @media screen and (max-width: 1090px) {
            font-size: 18px;
          }

          @media screen and (max-width: 520px) {
            font-size: 14px;
          }

          .mini-wax-logo {
            width: 10px;
            height: 10px;
          }

          span {
            color: #e65634;
          }
        }
      }
    }

    .about {
      .rules {
        width: 469px;
        min-height: 200px;
        background: #111122;
        border-radius: 5px;
        margin-bottom: 8px;
        position: relative;
        padding: 0 26px;

        @media screen and (max-width: 1090px) {
          width: 754px;
        }

        @media screen and (max-width: 787px) {
          width: 500px;
          margin: 0 auto 14px auto;
        }

        @media screen and (max-width: 520px) {
          width: 350px;
        }

        .edit {
          position: absolute;
          top: 10px;
          right: 10px;

          button {
            color: #ffffff;
            font-size: 12px;
            width: 93px;
            height: 24px;
            background: #2b282c;
            border-radius: 15px;
            border: none;

            &:hover {
              background: rgba(43, 40, 44, 0.87);
            }
          }
        }

        &_title {
          padding-top: 11px;
          margin-bottom: 15px;
        }

        &_subtitle {
          font-size: 14px;
          margin-bottom: 19px;
        }

        &_collection-block{
          padding: 8px 15px 0 8px;
          width: 265px;
          height: 76px;
          background: #000000;
          font-size: 14px;
          margin-bottom: 20px;

          p{
            text-align: left;
          }

          .collection-inside{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            margin-top: 13px;

            .amount-nft{
              font-size: 22px;
            }

            .name-collection{
              display: flex;
              gap: 10px;
              align-items: center;

              img{
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        &_schema, &_template {
          text-align: left;
          span{
            font-size: 14px;
            color: #D8D8D8;
          }

          p{
            color: #ffffff;
            font-size: 16px;
          }
        }

        &_schema{
          margin-bottom: 10px;
        }

        &_template{
          padding: 1px;
        }

      }
  
    }
    .final-info{
      width: 469px;
      min-height: 100px;
      background: #111122;
      border-radius: 5px;
      margin-bottom: 14px;
      position: relative;
      padding: 20px 26px;

      &_block{
        display: flex;
        justify-content: space-between;
        text-align: left;
        width: 375px;
        height: 70px;
        padding: 6px 83px 0 8px;
        background: #000000;
        margin-bottom: 20px;

        // &-title{
        //   display: flex;
        //   flex-direction: column;
        //   gap: 10px;
        // }

        &-title, &-title-second{
          color: #D8D8D8;
          font-size: 14px;
          margin-bottom: 11px;

          .subtitle{
            font-size: 14px;
            margin-bottom: 6px;
          }

        }


        &-info{
          display: flex;
          justify-content: space-between;
          align-items: center;

          .amount{
            font-size: 22px;
            color: #ffffff;
          }

          .collection-name{
            font-size: 18px;
          }
        }
      }

      &_schema, &_template{
        text-align: left;
        &-span{
          color: #D8D8D8;
          font-size: 14px;
          margin-bottom: 13px;
        }
  
        &-p{
          font-size: 16px;
          color: #ffffff;
          margin-bottom: 20px;
        }
      }

    }

   

  
  }

}

.final-info_schema{
  .final-info_schemas{
    color: #D8D8D8;
    font-size: 14px;
    margin-bottom: 13px;
  }
}

.final-info_template, .rules{

  .templates-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 14px 0;

    &_amount{
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 8px;
      padding: 4px 13px 11px 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;

      &-span{
        color: #D8D8D8;
        font-size: 14px;
      }

      .number{
        font-size: 18px!important;
        color: #FFFFFF;
        margin-bottom: 0!important;
      }
    }

    &_info{
      width: 100%;
      height: 100%;
      background: #000000 0% 0% no-repeat padding-box;
      border-radius: 8px;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;

    }
  }
}

.final-info_block-info{
  gap: 8px;
  .name-collection{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 5px;

    img{
      width: 24px;
      height: 24px;
    }
  }
}


