.rodal {
  &-dialog {
    background: #2b282c;
    //width:900px!important;
    //height: 156px!important;
    padding: 15px 32px !important;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #000000;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .title {
      h2 {
        font-size: 20px;
        color: #ffffff;
        margin-bottom: 8px;
        position: relative;
      }

      .sub-title {
        font-size: 12px;
        color: #ffffff;
        text-align: center;
      }
    }

    .content {
      margin-top: 45px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 65px;
      color: #ffffff;

      @media screen and (max-width: 999px) {
        display: block;
      }

      &-collections,
      &-schema,
      &-templates {
        position: relative;

        button {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @media screen and (max-width: 999px) {
            width: 100% !important;
            margin-bottom: 16px;
          }
        }

        .coll-block {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 18px;
            margin-right: 10px;
          }
        }

        .dropdown-menu {
          width: 94%;
          max-height: 270px;
          background-color: #000000;
          position: absolute;
          top: 40px;
          left: 0;
          z-index: 3;
          overflow-y: auto;
          overflow-x: hidden;

          @media screen and (max-width: 999px) {
            width: 100%;
          }

          .search-collection {
            width: 201px;
            height: 22px;
            background: #2b282c;
            color: #ffffff;
            font-size: 12px;
            margin-bottom: 6px;
            margin-top: 10px;
            padding: 0 10px;
            border: 0;
          }

          .item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 85%;
            height: 30px;
            text-align: left;
            font-size: 12px;
            opacity: 1;
            padding: 0 10px;

            img {
              width: 20px;
              //height: 20px;
              margin-right: 10px;
            }

            .about {
              display: flex;
              align-items: center;
              flex-direction: row;

              p:last-child {
                color: #8e8e8e;
              }

              .verified {
                width: 9px;
                height: 9px;

                img {
                  width: 100%;
                  height: 100%;
                  margin-left: 10px;
                }
              }
            }

            &:hover {
              background-color: #1c1c1c;
              cursor: pointer;
            }
          }
        }
      }

      &-schema {
        justify-content: flex-start;

        label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }

      &-templates {
        label {
          width: 100%;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
        }
      }

      &-add {
        button {
          @media screen and (max-width: 999px) {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }
      }

      &-delete {
        cursor: pointer;
        p {
          color: #626162;
          font-family: Lexend-Regular, sans-serif;
          font-size: 18px;
        }
      }
    }
  }

  .added-collections {
    position: absolute;
    bottom: -130px;
    left: 0;
    color: #ffffff;
    text-align: left;
    background: #2b282c;
    width: 900px !important;
    height: 105px !important;
    padding: 15px 32px !important;

    @media screen and (max-width: 999px) {
      width: 615px !important;
      height: 100px !important;

      bottom: 150px;
    }

    @media screen and (max-width: 720px) {
      width: 400px !important;
    }

    @media screen and (max-width: 489px) {
      width: 300px !important;
    }

    &_list {
      //height: 93px;
      overflow-y: auto;
      overflow-x: hidden;

      &_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 878px;
        height: 44px;
        background: #111122;
        border-radius: 5px;
        padding: 0 15px;
        margin: 0 0 5px 0;

        @media screen and (max-width: 999px) {
          width: 584px;
        }

        @media screen and (max-width: 720px) {
          width: 368px;
        }

        @media screen and (max-width: 489px) {
          width: 267px;
        }

        .collection_name {
          display: flex;
          align-items: center;
          .collection-image {
            width: 18px;
            margin-right: 8px;

            img {
              width: 100%;
            }
          }
        }

        .close {
          cursor: pointer;
        }
      }
    }

    .confirmation {
      margin-top: 20px;
      text-align: center;
    }
  }
}
